<template>
  <div :element-loading-background="loadingBackground" v-loading="loading">
    <el-table
      border
      ref="tableList"
      :height="tableHeight + 'px'"
      :data="phoneRecordList"
      highlight-current-row
    >
      <!-- <el-table-column type="selection" width="50"></el-table-column> -->
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="80">
        <template slot-scope="scope">
          <e6-td-operate
            :data="getOperateList(scope.row)"
            @command="handleOperate($event, scope.row)"
          ></e6-td-operate>
        </template>
      </el-table-column>
    </el-table>
    <play-video
      :recordUrl="curSrc"
      :dialogVisible="dialogVisible"
      @handleClose="handleClose"
    ></play-video>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { getTelephoneRecord } from "@/api";
import PlayVideo from "@/components/playVideo.vue";
import { printError } from "@/utils/util";
export default {
  name: "queryPhone",
  components: { PlayVideo },
  mixins: [base],
  data() {
    return {
      columnData: [
        {
          fieldName: "workNo",
          fieldLabel: "任务单号",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scheduleTaskId",
          display: true,
          fieldLabel: "调度任务ID",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scheduleInfoId",
          display: true,
          fieldLabel: "调度信息ID",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerName",
          display: true,
          fieldLabel: "联系人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "customerPhone",
          display: true,
          fieldLabel: "被联系人号码",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "customerName",
          display: true,
          fieldLabel: "被联系人名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerCallTypeStr",
          display: true,
          fieldLabel: "来去电类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "callTime",
          display: true,
          fieldLabel: "联系时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "duration",
          display: true,
          fieldLabel: "通话时长",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "callResultStr",
          display: true,
          fieldLabel: "通话结果",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      phoneRecordList: [],
      curSrc: "", //当前播放录音地址
      dialogVisible: false,
      refTable: "tableList",
      queryListAPI: getTelephoneRecord
    };
  },
  props: ["type", "taskId", "tableHeight"],
  computed: {},
  watch: {
    type(val) {
      console.log(val, "!!!");
      if (val == "seven") {
        //调度任务
        this.phoneRecordList = [];
        this.getRecordListReq();
      }
    }
  },
  created() {},
  mounted() {},
  activated() {},
  methods: {
    async getRecordListReq() {
      try {
        this.loading = true;
        let res = await getTelephoneRecord({
          taskIdList: [this.taskId]
        });
        //基本信息
        this.phoneRecordList = res.data.array;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList(row) {
      let operates = [];
      if (row.recordAccessUrl) {
        operates = [
          {
            label: "播放录音",
            id: 1
          }
        ];
      }
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.dialogVisible = true;
        this.curSrc = row.recordAccessUrl;
      }
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
